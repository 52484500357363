import { Switch as ReactSwitch, SwitchProps } from "react-aria-components";

import "./Switch.scss";

export function Switch(
    props: SwitchProps & React.RefAttributes<HTMLLabelElement>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
    return (
        <ReactSwitch
            id={props.id}
            className="vmSwitch"
            isSelected={props.isSelected}
            onChange={props.onChange}
            style={props.style}
        >
            {props.children}
        </ReactSwitch>
    );
}
