import { useIntl } from "react-intl";

import { Card } from "../../../base";
import { useBrandingConfig } from "../../../modules/branding";
import "./MainCard.scss";

export function MainCard(): React.ReactElement {
    const intl = useIntl();

    const brandingConfig = useBrandingConfig();

    return (
        <Card additionalClassName="vm_MainCard">
            <div className="vm_MainCard-image">
                {brandingConfig?.logoUrl != undefined && (
                    <img className="vm_MainCard-logo" src={brandingConfig?.logoUrl} alt="brand_logo" />
                )}
            </div>
            <h2 className="vm_MainCard-heading">
                {intl.formatMessage({ id: "main.welcome", defaultMessage: "Welcome to Your Meeting Scheduler" })}
            </h2>
        </Card>
    );
}
