import React from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";

import { FormButton } from "../../../base";
import { AppointmentRoute } from "../../appointments";

export function AppointmentNotFoundPage(): React.ReactElement {
    const intl = useIntl();
    const navigate = useNavigate();

    return (
        <div id="error-page">
            <h1>{intl.formatMessage({ id: "error.appointmentNotFound.title", defaultMessage: "Not found" })}</h1>
            <p>
                {intl.formatMessage({
                    id: "error.appointmentNotFound.message",
                    defaultMessage: "This appointment was not found.",
                })}
            </p>
            <FormButton
                onPress={() => navigate(AppointmentRoute.ListConferencesPage)}
                text={intl.formatMessage({
                    id: "error.appointmentNotFound.goToOverview",
                    defaultMessage: "Go to Overview",
                })}
            />
        </div>
    );
}
